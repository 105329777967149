<template>
  <!-- 容器 -->
  <div class="index-container">
    <div class="box">
      <div class="item" style="background: #69bf9a">
        <i class="el-icon-shopping-cart-full"></i>
        <div class="right">
          <div>待合单</div>
          <div>{{ amountInfo.planWaybillCounts }}</div>
        </div>
      </div>
      <div class="item" style="background: #e8a138">
        <i class="el-icon-connection"></i>
        <div class="right">
          <div>待发布</div>
          <div>{{ amountInfo.waitPublishWaybillCounts }}</div>
        </div>
      </div>
      <div class="item" style="background: #599bcd">
        <i class="el-icon-mobile-phone"></i>
        <div class="right">
          <div>待派车</div>
          <div>{{ amountInfo.initialWaybillCounts }}</div>
        </div>
      </div>
      <div class="item" style="background: #eb746f">
        <i class="el-icon-document"></i>
        <div class="right">
          <div>待揽收</div>
          <div>{{ amountInfo.waitCollectionCounts }}</div>
        </div>
      </div>
      <div class="item" style="background: #f1c232">
        <i class="el-icon-s-flag"></i>
        <div class="right">
          <div>待发车</div>
          <div>{{ amountInfo.waitCarsCounts }}</div>
        </div>
      </div>
      <div class="item" style="background: #ed72c8">
        <i class="el-icon-map-location"></i>
        <div class="right">
          <div>待送达</div>
          <div>{{ amountInfo.waitArriveCounts }}</div>
        </div>
      </div>
      <div class="item" style="background: #4caeea">
        <i class="el-icon-collection"></i>
        <div class="right">
          <div>待签收</div>
          <div>{{ amountInfo.waitSignCount }}</div>
        </div>
      </div>
    </div>
    <div class="top-chart">
      <!-- echarts饼图 -->
      <div id="pie-chart" class="pie-chart">
        <div ref="pieCharts"></div>
      </div>
      <!-- echarts柱状图 -->
      <div class="bar-chart">
        <div ref="barCharts"></div>
      </div>
      <!-- echarts柱状图 -->
      <div class="bar-chart1">
        <div ref="barCharts1"></div>
      </div>
      <div class="progress" ref="progress">
        <div class="title">本月综合统计</div>
        <div class="content">
          <div class="fl">
            <div class="element1">
              <el-progress :width="50" :show-text="false" type="circle" :percentage="0"> </el-progress>
              <div class="child1">
                <div class="num">{{ progressInfo.totalPackages }}</div>
                <div class="label">总件数</div>
              </div>
            </div>
            <div class="element1">
              <el-progress :width="50" :show-text="false" type="circle" :percentage="0"> </el-progress>
              <div class="child1">
                <div class="num">{{ progressInfo.batches }}</div>
                <div class="label">车次数</div>
              </div>
            </div>
            <div class="element1">
              <el-progress :width="50" :show-text="false" type="circle" :percentage="0"> </el-progress>
              <div class="child1">
                <div class="num yellow">{{ progressInfo.workTime.toFixed(1) }}H</div>
                <div class="label">平均时长</div>
              </div>
            </div>
          </div>
          <div class="fl">
            <div class="element1">
              <el-progress :width="50" :show-text="false" type="circle" :percentage="0"> </el-progress>
              <div class="child1">
                <div class="num">{{ progressInfo.customers }}</div>
                <div class="label">客户数</div>
              </div>
            </div>
            <div class="element1">
              <el-progress :width="50" :show-text="false" type="circle" :percentage="0"> </el-progress>
              <div class="child1">
                <div class="num red">{{ progressInfo.exceptions }}</div>
                <div class="label">异常次数</div>
              </div>
            </div>
            <div class="element1">
              <el-progress :width="50" :show-text="false" type="circle" :percentage="0"> </el-progress>
              <div class="child1">
                <div class="num green">{{ progressInfo.signRate.toFixed(1) }}%</div>
                <div class="label">签收率</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom-chart">
      <!-- 横柱状图 -->
      <div class="bar-chart2">
        <div ref="barCharts2"></div>
      </div>
      <div class="line-chart">
        <div ref="lineChart"></div>
      </div>
      <div class="line-chart1">
        <div ref="lineChart1"></div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import * as echarts from 'echarts'
export default {
  data() {
    return {
      amountInfo: {
        planWaybillCounts: 0,
        waitPublishWaybillCounts: 0,
        initialWavbillCounts: 0,
        waitCollectionCounts: 0,
        waitCarsCounts: 0,
        waitArriveCounts: 0,
        waitSignCount: 0,
      },
      progressInfo: {
        totalPackages: 0,
        customers: 0,
        batches: 0,
        exceptions: 0,
        workTime: 0,
        signRate: 0,
      },
      pieCharts: null, // charts实例
      barCharts: null,
      barCharts1: null,
      barCharts2: null,
      lineChart: null,
      lineChart1: null,
    }
  },
  methods: {
    initPieData(data) {
      if (this.pieCharts === null) {
        this.pieCharts = echarts.init(this.$refs.pieCharts)
      }
      const option = {
        title: {
          text: '本月配送时效分布图',
          left: 'center',
          top: '10',
          textStyle: { fontSize: 14, color: '#808080' },
        },
        tooltip: {
          trigger: 'item',
        },
        color: ['rgb(100,183,148)', '#f6c143', '#699ad0', '#df8244'],
        series: [
          {
            type: 'pie',
            radius: '50%',
            data: [
              { value: data.otherPercent, name: '其他' },
              { value: data.afterTomorrowPercent, name: '隔日达' },
              { value: data.tomorrowPercent, name: '次日达' },
              { value: data.todayPercent, name: '当日达' },
            ],
            center: ['50%', '64%'],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)',
              },
            },
          },
        ],
        label: {
          alignTo: 'edge',
          formatter: '{name|{b}}\n{value|{c}%}',
          minMargin: 5,
          edgeDistance: 10,
          lineHeight: 15,
          rich: {
            value: {
              fontSize: 10,
              color: '#000',
            },
          },
        },
      }
      option && this.pieCharts.setOption(option)
    },
    initBarData(data) {
      const xData = data && data.map(e => e.vendeeName)
      const yData = data && data.map(e => e.totalPkgQty)
      if (this.barCharts === null) {
        this.barCharts = echarts.init(this.$refs.barCharts)
      }
      const option = {
        title: {
          text: '本月配送top10客户(件数)',
          left: 'center',
          top: '10',
          textStyle: { fontSize: 14, color: '#808080' },
        },
        xAxis: {
          type: 'category',
          data: xData,
          triggerEvent: true,
          axisLabel: {
            // interval: auto,
            rotate: 45,
            formatter: function (value) {
              if (value.length > 4) {
                return `${value.slice(0, 4)}...`
              }
              return value
            },
          },
        },
        yAxis: {
          type: 'value',
        },
        grid: {
          left: '16%',
          // right: '4%',
          // bottom: '1%',
          // containLabel: true
        },
        color: '#4bace7',
        series: [
          {
            data: yData,
            type: 'bar',
          },
        ],
      }
      option && this.barCharts.setOption(option)
      this.extension(this.barCharts, 'xAxis')
    },
    initBarData1(data) {
      const xData = data && data.map(e => e.vendeeName)
      const yData = data && data.map(e => e.batches)
      if (this.barCharts1 === null) {
        this.barCharts1 = echarts.init(this.$refs.barCharts1)
      }
      const option = {
        title: {
          text: '本月配送top10客户(频次)',
          left: 'center',
          top: '10',
          textStyle: { fontSize: 14, color: '#808080' },
        },
        color: '#efbb41',
        xAxis: {
          type: 'category',
          data: xData,
          triggerEvent: true,
          axisLabel: {
            // interval: auto,
            rotate: 45,
            formatter: function (value) {
              if (value.length > 4) {
                return `${value.slice(0, 4)}...`
              }
              return value
            },
          },
        },
        yAxis: {
          type: 'value',
        },
        // grid: {
        //   left: '3%',
        //   right: '4%',
        //   bottom: '1%',
        //   containLabel: true
        // },
        series: [
          {
            data: yData,
            type: 'bar',
          },
        ],
      }
      option && this.barCharts1.setOption(option)
      this.extension(this.barCharts1, 'xAxis')
    },
    initBarData2(data) {
      const xData = data && data.map(e => e.totalPkgQty)
      const yData = data && data.map(e => e.carriersName)
      if (this.barCharts2 === null) {
        this.barCharts2 = echarts.init(this.$refs.barCharts2)
      }
      const option = {
        title: {
          text: '本月承运商TOP5配送量(件)',
          left: 'center',
          top: '10',
          textStyle: { fontSize: 14, color: '#808080' },
        },
        color: '#69bf9a',
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow',
          },
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '4%',
          containLabel: true,
        },
        xAxis: {
          type: 'value',
        },
        yAxis: {
          type: 'category',
          data: yData,
          triggerEvent: true,

          axisLabel: {
            formatter: function (value) {
              if (value.length > 6) {
                return `${value.slice(0, 6)}...`
              }
              return value
            },
          },
        },
        series: [
          {
            type: 'bar',
            data: xData,
            // label: {
            //   show: true
            // },
          },
        ],
      }
      option && this.barCharts2.setOption(option)
      this.extension(this.barCharts2, 'yAxis')
    },
    initLineData(data) {
      const xData = data && data.map(e => e.day)
      const yData = data && data.map(e => e.percent)
      if (this.lineChart === null) {
        this.lineChart = echarts.init(this.$refs.lineChart)
      }
      const option = {
        title: {
          left: 'center',
          text: '7天轨迹完整率(%)',
          top: '10',
          textStyle: { fontSize: 14, color: '#808080' },
        },
        xAxis: {
          type: 'category',
          data: xData,
        },
        yAxis: {
          type: 'value',
        },
        color: '#699ad0',
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true,
        },
        series: [
          {
            data: yData,
            type: 'line',
            label: {
              show: true,
            },
          },
        ],
      }
      console.log(option)
      option && this.lineChart.setOption(option)
    },
    initLineData1(data) {
      const xData = data && data.map(e => e.day)
      const yData = data && data.map(e => e.total)
      if (this.lineChart1 === null) {
        this.lineChart1 = echarts.init(this.$refs.lineChart1)
      }
      const option = {
        title: {
          left: 'center',
          text: '本月货量走势图(件数)',
          top: '10',
          textStyle: { fontSize: 14, color: '#808080' },
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true,
        },
        color: '#699ad0',
        xAxis: {
          type: 'category',
          data: xData,
        },
        yAxis: {
          type: 'value',
        },
        series: [
          {
            data: yData,
            type: 'line',
            // label: {
            //   show: true
            // },
          },
        ],
      }
      option && this.lineChart1.setOption(option)
    },
    getPieData() {
      axios
        .get(`${this.$apiPath}/api/urban/board/timeEfficiency`)
        .then(res => {
          console.log(res.data)
          this.initPieData(res.data.data)
        })
        .catch(err => {
          console.error(err)
        })
    },
    getBarData() {
      axios
        .get(`${this.$apiPath}/api/urban/board/topCustomerPackages`)
        .then(res => {
          console.log(res.data)
          this.initBarData(res.data.data)
        })
        .catch(err => {
          console.error(err)
        })
    },
    getBarData1() {
      axios
        .get(`${this.$apiPath}/api/urban/board/topCustomerBatchCounts`)
        .then(res => {
          console.log(res.data)
          this.initBarData1(res.data.data)
        })
        .catch(err => {
          console.error(err)
        })
    },
    getBarData2() {
      axios
        .get(`${this.$apiPath}/api/urban/board/topCarrierPackages`)
        .then(res => {
          console.log(res.data)
          this.initBarData2(res.data.data)
        })
        .catch(err => {
          console.error(err)
        })
    },
    getLineData() {
      axios
        .get(`${this.$apiPath}/api/urban/board/topMapPercent`)
        .then(res => {
          console.log(res.data)
          this.initLineData(res.data.data)
        })
        .catch(err => {
          console.error(err)
        })
    },
    getLineData1() {
      axios
        .get(`${this.$apiPath}/api/urban/board/transportDirection`)
        .then(res => {
          console.log(res.data)
          this.initLineData1(res.data.data)
        })
        .catch(err => {
          console.error(err)
        })
    },
    getProgressData() {
      axios
        .get(`${this.$apiPath}/api/urban/board/multipleCount`)
        .then(res => {
          console.log(res.data)
          this.$set(this.$data, 'progressInfo', res.data.data || {})
        })
        .catch(err => {
          console.error(err)
        })
    },
    /**
     * 查询
     */
    getInfo() {
      axios
        .get(`${this.$apiPath}/api/urban/board/totalCounts`)
        .then(res => {
          console.log(res.data)
          this.$set(this.$data, 'amountInfo', res.data.data || {})
        })
        .catch(err => {
          console.error(err)
        })
    },
    extension(chart, type) {
      var elementDiv = document.getElementById('extension')
      if (!elementDiv) {
        var div = document.createElement('div')
        div.setAttribute('id', 'extension')
        div.style.display = 'block'
        document.querySelector('html').appendChild(div)
      }
      chart.on('mouseover', function (params) {
        if (params.componentType == type) {
          var elementDiv = document.querySelector('#extension')
          var elementStyle =
            'position: absolute;z-index: 99999;color: #fff;font-size: 12px;padding: 5px;display: inline;border-radius: 4px;background-color: #303133;box-shadow: rgba(0, 0, 0, 0.3) 2px 2px 8px'
          elementDiv.style.cssText = elementStyle
          elementDiv.innerHTML = params.value
          document.querySelector('html').onmousemove = function (event) {
            var elementDiv = document.querySelector('#extension')
            var xx = event.pageX + 10
            var yy = event.pageY - 30
            elementDiv.style.top = yy + 'px'
            elementDiv.style.left = xx + 'px'
          }
        }
      })
      chart.on('mouseout', function (params) {
        if (params.componentType == type) {
          var elementDiv = document.querySelector('#extension')
          elementDiv.style.cssText = 'display:none'
        }
      })
    },
    getHeightData() {
      let dom = document.getElementById('pie-chart')
      let pieCharts = this.$refs.pieCharts
      let barCharts = this.$refs.barCharts
      let barCharts1 = this.$refs.barCharts1
      let barCharts2 = this.$refs.barCharts2
      let lineChart = this.$refs.lineChart
      let lineChart1 = this.$refs.lineChart1
      // pieCharts.style.width = dom.clientWidth + 'px'
      // barCharts.style.width = dom.clientWidth + 'px'
      // barCharts1.style.width = dom.clientWidth + 'px'
      // barCharts2.style.width = dom.clientWidth + 'px'
      // lineChart.style.width = dom.clientWidth + 'px'
      // lineChart1.style.width = dom.clientWidth + 'px'

      pieCharts.style.height = dom.clientHeight + 'px'
      barCharts.style.height = dom.clientHeight + 'px'
      barCharts1.style.height = dom.clientHeight + 'px'
      barCharts2.style.height = dom.clientHeight + 'px'
      lineChart.style.height = dom.clientHeight + 'px'
      lineChart1.style.height = dom.clientHeight + 'px'

      // pieCharts.style.height = dom.clientHeight*1.1 + 'px'
      // barCharts.style.height = dom.clientHeight*1.1 + 'px'
      // barCharts1.style.height = dom.clientHeight*1.1 + 'px'
      // barCharts2.style.height = dom.clientHeight*1.1 + 'px'
      // lineChart.style.height = dom.clientHeight*1.1 + 'px'
      // lineChart1.style.height = dom.clientHeight*1.1 + 'px'
    },
    getresize() {
      let dom = this.$refs.progress
      this.pieCharts.resize()
      this.barCharts.resize()
      this.barCharts1.resize()
      this.barCharts2.resize()
      this.lineChart.resize()
      this.lineChart1.resize()
      dom.resize()
      this.getHeightData()
    },
  },
  mounted() {
    this.getInfo()
    this.getPieData()
    this.getBarData()
    this.getBarData1()
    this.getBarData2()
    this.getLineData()
    this.getLineData1()
    this.getProgressData()
    this.getHeightData()
    window.addEventListener('resize', this.getresize)
  },
  beforeDestroy() {
    // 页面销毁时销毁监听事件
    window.removeEventListener('resize', this.getresize)
  },
}
</script>
<style lang="scss" scoped>
.index-container {
  height: 100%;
  width: 100%;
  background: #fff;
  // overflow:auto;
  .box {
    display: flex;
    justify-content: space-around;
    .item {
      width: 13%;
      height: 60px;
      margin-top: -10px;
      align-items: center;
      color: #ffffff;
      display: flex;
      justify-content: space-around;
      .right {
        text-align: center;
        margin-left: -20px;
        div:nth-child(2) {
          font-size: 24px;
        }
      }
      i {
        font-size: 40px;
      }
    }
  }
  .top-chart {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    height: 40%;
    margin-top: 10px;
    margin-left: 6px;
    .pie-chart {
      height: 100%;
      width: 25%;
      border: 1.5px solid #e6e6e8;
      border-right: none;
    }
    .bar-chart {
      height: 100%;
      width: 30%;
      border: 1.5px solid #e6e6e8;
      border-right: none;
    }
    .bar-chart1 {
      height: 100%;
      width: 30%;
      border: 1.5px solid #e6e6e8;
    }
    .progress {
      height: 100%;
      width: 12.87%;
      border: 1.5px solid #e6e6e8;
      border-left: none;
      //margin: 0 auto;
      .title {
        text-align: center;
        font-size: 13px;
      }
      .content {
        display: flex;
        justify-content: center;
        //margin-left: 8px;
        margin-top: 2px auto;
        .element1 {
          position: relative;
          text-align: center;
          width: 60px;
          // width: 60%;
          // height: 30%;
          // background-color: #bfbfbf;
          // // border-radius: 50%;
          // margin-right: 10px;
          // margin-bottom:20px;
          // position:relative;
          .child1 {
            // width: 50%;
            // height: 20%;
            // // border-radius: 50%;
            // background-color: #fff;
            // position: absolute;
            // top: 5%;
            // left: 5%;
            // text-align: center;
            position: absolute;
            top: 10px;
            left: 10px;
            .num {
              // font-weight: 700;
              font-size: 12px;
              //padding-top:1px;
            }
            .label {
              font-size: 12px;
              // font-weight: 800;
              color: #808080;
            }
            .red {
              color: #ea2d0c;
              font-size: 12px;
            }
            .yellow {
              color: #f5bb00;
              font-size: 12px;
            }
            .green {
              color: #4aac6d;
              font-size: 12px;
            }
          }
        }
      }
    }
  }
  .bottom-chart {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    //height:100%;
    margin-left: 6px;
    .bar-chart2 {
      height: 38%;
      width: 35%;
      border: 1.5px solid #e6e6e8;
      border-top: none;
    }
    .line-chart {
      height: 38%;
      width: 25%;
      border: 1.5px solid #e6e6e8;
      border-top: none;
      border-left: none;
      border-right: none;
    }
    .line-chart1 {
      height: 38%;
      width: 38%;
      border: 1.5px solid #e6e6e8;
      border-top: none;
    }
  }
}
</style>
